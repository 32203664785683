export enum SearchProcedureOrdenation {
  RELEVANCE = 'relevance',
  PRICE = 'price',
  DISTANCE = 'distance',
}

export interface OrdenationOption {
  name: string
  value: SearchProcedureOrdenation
}

export const SearchProcedureOrdenationMapping = {
  [SearchProcedureOrdenation.RELEVANCE]: { name: 'Maior relevância', value: SearchProcedureOrdenation.RELEVANCE } as OrdenationOption,
  [SearchProcedureOrdenation.PRICE]: { name: 'Menor preço', value: SearchProcedureOrdenation.PRICE } as OrdenationOption,
  [SearchProcedureOrdenation.DISTANCE]: { name: 'Menor distância', value: SearchProcedureOrdenation.DISTANCE } as OrdenationOption,
}
