export const useGeolocationPopupActiveState = () => useState('UseLocationPopupActiveState', () => false)

export function useGeolocationPopupOpen() {
  const closedToCurrentSession = sessionStorage.getItem('popup-closed')

  if (!closedToCurrentSession)
    useGeolocationPopupActiveState().value = true
}

export function useGeolocationPopupCloseInSession() {
  useGeolocationPopupActiveState().value = false

  sessionStorage.setItem('popup-closed', 'true')
}

export function useGeolocationPopupClose() {
  useGeolocationPopupActiveState().value = false
}
