import { SearchProcedureOrdenation, SearchProcedureOrdenationMapping } from '@/modules/search/types/enums'
import { EventName } from '@/modules/trevo/types/enum'
import type { NuxtError } from '#app'
import type { ISearchProcedureResponse } from '@/modules/search/types/api.d'

interface ISearchProcedure {
  data: ComputedRef<any>
  pending: ComputedRef<boolean>
  error: ComputedRef<NuxtError | null>
}

const path = '/v1/marketplace/procedure_displays/search'
export default function (term: Ref<string>, instanceKey: string = 'default'): ISearchProcedure {
  const baseURL = useRuntimeConfig().public.api.awsGateway

  const { pending, data, error } = useAsyncData<ISearchProcedureResponse | null>(`useSearchProcedures-${instanceKey}`, async () => {
    if (term.value.length < 2)
      return null

    const fetched = await $fetch<ISearchProcedureResponse>(path, { baseURL, query: { q: term.value } })

    track(EventName.ExamInput, {
      searchTerm: term.value,
      resultsQuantity: fetched.items.length,
    })

    return fetched
  }, { watch: [term], server: false })

  return {
    data: computed(() => data.value && data.value.items ? data.value.items : []),
    pending: computed(() => !!(pending.value && term.value.length)),
    error: computed(() => error.value),
  }
}

export function useSearchProcedureOrdenation() {
  return useState('SearchProcedureOrdenation', () => SearchProcedureOrdenationMapping[SearchProcedureOrdenation.RELEVANCE])
}
